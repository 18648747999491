import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { firestorePlugin } from 'vuefire'
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
// import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css'

// import { VueReCaptcha } from 'vue-recaptcha-v3'
// Vue.use(VueReCaptcha, {siteKey: '6LfnikwaAAAAAH0xUwBB1rLNabm2g6PtTYAiTxPa'})

Vue.config.productionTip = false
Vue.use(firestorePlugin)

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
