<template>
  <div id="app">
    <router-view/>
    <div id="footer">
      <div class="copyright">&copy; 2021 At First Site App Ltd.</div>
      <div class="privacyAndCookies">
        <span @click.prevent="gotoPrivacy">Privacy</span>
        {{ '\xa0' }}{{ '\xa0' }}|{{ '\xa0' }}{{ '\xa0' }}
        <span @click.prevent="gotoCookies">Cookies</span>
      </div>
      <!--        <div style="float: right; margin-right: 15px"><a href="#" @click.prevent="showPrivacy()">Privacy</a></div>-->
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted() {
    // const windowWidthOuter = window.outerWidth
    // const windowWidthInner = window.innerWidth
    // console.log(`windowWidthOuter: ${windowWidthOuter}`)
    // console.log(`windowWidthInner: ${windowWidthInner}`)
    // const windowHeightOuter = window.outerHeight
    // const windowHeightInner = window.innerHeight
    // console.log(`windowHeightOuter: ${windowHeightOuter}`)
    // console.log(`windowHeightInner: ${windowHeightInner}`)
    // alert(`
    // windowWidthOuter: ${windowWidthOuter}
    // windowWidthInner: ${windowWidthInner}
    // windowHeightOuter: ${windowHeightOuter}
    // windowHeightInner: ${windowHeightInner}
    // `)
  },
  methods: {
    gotoPrivacy() {
      if (this.$router.currentRoute.path !== '/privacy') {
        this.$router.push('/privacy')
      }
    },
    gotoCookies() {
      if (this.$router.currentRoute.path !== '/cookies') {
        this.$router.push('/cookies')
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #FFFFFF;

  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(162.44deg, #093432 0%, #116763 15.98%, #00A99D 52.58%, #ADE3E0 100%);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;
}

#columnContainer {
  margin-top: calc(-82/646 * 100vh);
  max-width: 800px;
}

#footer {
  position: fixed;
  width: 100vw;
  height: 34px;
  bottom: 0px;
  color: white;
  background: #093432;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8em;
  line-height: 16px;
  padding-top: 10px;

}

.copyright {
  float: left;
  margin-left: 15px;
}

.privacyAndCookies {
  float: right;
  margin-right: 15px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}


@media screen and (max-width: 314px) {
  #footer {
    font-size: 0.73em;
  }

  .copyright {
    margin-left: 7px;
  }

  .privacyAndCookies {
    margin-right: 7px;
  }
}


.full-height {
  min-height: 100vh;
}
</style>
