// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export default {
  config: {
    apiKey: "AIzaSyD8OEoFsBDbTBxwq-elF6h--VE4oSzIQNQ",
    authDomain: "atfirstsite-fb481.firebaseapp.com",
    projectId: "atfirstsite-fb481",
    storageBucket: "atfirstsite-fb481.appspot.com",
    messagingSenderId: "379724803550",
    appId: "1:379724803550:web:f48fd75c0a3ce8179d814f",
    measurementId: "G-1CV6X3NC9H"
  }
}
