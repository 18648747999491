<template>
  <div id="columnContainer">
    <div>
      <div class="column" style="margin: auto">

        <div class="row-0"></div>

        <div class="row-1">
          <div id="neverMissConnectionAgain"/>
        </div>

        <div class="row-2">
          <div id="doYouWish"/>
        </div>

        <div class="row-3">
          <div id="yeah"/>
        </div>

        <div class="row-4">
          <div id="signUp"/>
        </div>

        <div class="row-5">
          <div id="formBackground">
            <form @submit.prevent="onSubmit(email)">
              <div id="theForm">
                <div style="position: relative; height: 10px;"></div>
                <input id="emailField"
                       v-model="email"
                       type="email"
                       placeholder="Email address"
                       class="form-control"
                />
                <button id="callToAction" type="submit">Sign me up</button>
              </div>
            </form>
          </div>
        </div>

        <div class="row-6">
          <div id="getSecondChanceLockup"/>
        </div>

      </div>

      <!--    <button @click="recaptcha">Execute recaptcha</button> -->

      <!--  ===========================================================================  -->
      <div id="dialog" style="visibility: hidden">
        <div id="dialogBox">
          <div style="height: 50px">
            {{ message }}
          </div>
          <button id="closeDialog" v-on:click="closeDialog()">Close</button>
        </div>
      </div>
      <!--  ===========================================================================  -->
      <div id="privacy" style="visibility: hidden">
        <div id="privacyBox">
          <div style="height: 50px">
            Privacy Statement
          </div>
          <button id="closePrivacy" v-on:click="closePrivacy()">Close</button>
        </div>
      </div>
      <!--  ===========================================================================  -->
    </div>
  </div>
</template>

<script>
import { Auth } from '@/firebase/auth'

export default {
  name: "HomePage",
  data() {
    return {
      email: '',
      message: '',
    }
  },

  methods: {
    showPrivacy() {

    },
    // recaptcha() {
    //   this.$recaptcha('login').then((token) => {
    //     console.log(token) // Will print the token
    //   })
    // },
    onSubmit: async function (email) {

      // this.$recaptcha('login').then((token) => {
      //   console.log(token) // Will print the token
      // })

      this.message = 'Success!'
      const pass = this.randomPassword(10)
      // console.log(email, pass)
      try {
        await Auth.createUserWithEmailAndPassword(email, pass)
        console.log('+++')
        document.getElementById('dialog').style.visibility = 'visible';
        window.fbq('track', 'Lead', {content_category: 'ValentinesTeaser2021'});
        this.email = ''

      } catch (e) {
        if (e.code !== 'auth/email-already-in-use') {
          this.message = 'Your email seems to be wrong..try again?'
          // this.message = e.message
        }
        // console.log(e)
        document.getElementById('dialog').style.visibility = 'visible';
        this.email = ''

      }

    },

    randomPassword(passLen) {
      const chars = 'abcdefghijklmnopqrstuvwxyz!@#$%^&*()_+<>ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
      let pass = ''
      for (let i = 0; i < passLen; i++) {
        let n = Math.floor(Math.random() * chars.length)
        pass += chars.charAt(n)
      }
      return pass
    },

    closeDialog() {
      // console.log('+++')
      document.getElementById('dialog').style.visibility = 'hidden';
      return
    },
  }

}
</script>

<style scoped>

.row-big {
  display: flex;
  align-items: center;
}

.column {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 400px));
  grid-template-areas: "z"
                       "a"
                       "b"
                       "c"
                       "d"
                       "e"
                       "f";
}

.row-0 {
  grid-area: z;
  height: 8vh;
}

.row-1 {
  grid-area: a;
  /*height: 10vh;*/
  /*margin-top: 0;*/
  /*margin-bottom: 0;*/
}

.row-2 {
  grid-area: b;
  /*height: 9vh;*/
  /*margin-top: 0;*/
  /*margin-bottom: 0;*/
}

.row-3 {
  grid-area: c;
  /*height: 6vh;*/
  /*margin-top: 0;*/
  /*margin-bottom: 0;*/
}

.row-4 {
  grid-area: d;
  /*height: 6vh;*/
  /*margin-top: 0;*/
  /*margin-bottom: 0;*/
}

.row-5 {
  grid-area: e;
  /*height: 6vh;*/
  /*margin-top: 0;*/
  /*margin-bottom: 0;*/
}

.row-6 {
  grid-area: f;
  /*height: 6vh;*/
  /*margin-top: 0;*/
  /*margin-bottom: 0;*/
}

#neverMissConnectionAgain {
  position: relative;
  margin: auto;
  padding-top: calc(81 / 320 * 100%);
  width: calc(320 / 320 * 100%);
  max-width: calc(320px * 2);
  /*width: 100%;*/
  /*max-width: 600px;*/
  background: url("../assets/never-miss-a-connection.svg") no-repeat center;
}

#doYouWish {
  position: relative;
  margin-left: 10px;
  padding-top: calc(71 / 218 * 85%);
  width: calc(218 / 320 * 100%);
  max-width: calc(218px * 2);
  background: url("../assets/do-you-wish.svg") no-repeat center;
}

#yeah {
  position: relative;
  float: right;
  margin-right: 10px;
  padding-top: calc(30 / 126 * 41%);
  width: calc(126 / 320 * 100%);
  max-width: calc(126px * 2);
  background: url("../assets/yeah.svg") no-repeat center;
}

#signUp {
  position: relative;
  margin-left: 10px;
  padding-top: calc(73 / 206 * 76%);
  width: calc(206 / 320 * 100%);
  max-width: calc(206px * 2);
  background: url("../assets/sign-up.svg") no-repeat center;
}

#formBackground {
  position: relative;
  float: right;
  margin-right: 10px;
  /*margin-bottom: 10px;*/
  padding-top: calc(141 / 259 * 87%);
  width: calc(259 / 320 * 100%);
  max-width: calc(259px * 2);
  background: url("../assets/im-in.svg") no-repeat right;
}

#theForm {
  position: relative;
  margin-top: calc(141 / 259 * -96%);
  /*margin-left: -10px;*/
}

#emailField {
  position: relative;
  width: 75%;
  max-width: 276px;
  height: 38px;
  padding-left: 20px;
  margin: auto;

  background: rgba(255, 255, 255, 0.74);
  border: 1px solid rgba(9, 52, 50, 0.56);
  box-sizing: border-box;
  border-radius: 29px;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

#callToAction {
  position: relative;
  width: 75%;
  max-width: 276px;
  height: 38px;
  margin-top: 0.4em;
  /*margin-bottom: 15px;*/

  background: #EC1E79;
  border: 1px solid #9D005D;
  box-sizing: border-box;
  border-radius: 29px;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  text-align: center;

  color: #FFFFFF;
}

#getSecondChanceLockup {
  position: relative;
  float: right;
  margin-right: 22px;
  margin-top: 10px;
  /*margin: auto;*/
  /*margin-top: calc(-18.14%/2);*/
  width: calc(215 / 280 * 100%);
  max-width: 600px;
  padding-top: calc(39 / 259 * 100%);
  background: url("../assets/get-a-second-change-lockup.svg") no-repeat center;
}

/*@media screen and (min-width: 768px) {*/
/*  #neverMissConnectionAgain {*/
/*    padding-top: 200px;*/
/*  }*/
/*}*/

/*@media screen and (min-width: 1024px) {*/
/*  #neverMissConnectionAgain {*/
/*    padding-top: 240px;*/
/*    margin-top: 175px;*/
/*  }*/
/*}*/

#dialog {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
}

#dialogBox {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 50%;
  max-width: 300px;
  max-height: 120px;
  padding: 15px;

  background: #F1F9F8;
  border: 1px solid #093432;
  box-sizing: border-box;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #000000;
}

#closeDialog {
  position: relative;
  margin: auto;
  width: 89px;
  height: 42px;
  bottom: 0px;
  /*left: 135px;*/
  /*top: 277px;*/

  background: #00A99D;
  border: 1px solid #093432;
  box-sizing: border-box;
  border-radius: 29px;
  padding-top: 3px;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  text-align: center;

  color: #FFFFFF;
}

#closePrivacy {
  position: relative;
  margin: auto;
  width: 89px;
  height: 42px;
  bottom: 0px;
  /*left: 135px;*/
  /*top: 277px;*/

  background: #00A99D;
  border: 1px solid #093432;
  box-sizing: border-box;
  border-radius: 29px;
  padding-top: 3px;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  text-align: center;

  color: #FFFFFF;
}

.centerMe {
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>