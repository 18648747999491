<template>
  <div id="cookies">
    <div id="goBack" @click.prevent="goBack">←</div>
    <div id="cookiesContainer"></div>
  </div>
</template>

<script>
export default {
  name: "Cookies",
  mounted() {
    const plugin = document.createElement('script')
    plugin.setAttribute('src', 'https://consent.cookiebot.com/8e536312-0bba-4ef1-a160-94f0ca7c26ae/cd.js')
    plugin.async = true
    const rootElement = document.getElementById('cookiesContainer')
    rootElement.appendChild(plugin)
  },
  methods: {
    goBack() {
      if (this.$router.currentRoute.path !== '/') {
        this.$router.push('/')
      }
    }
  }
}
</script>

<style scoped>

#goBack {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 45px;
  font-size: 2em;
  text-align: left;
  padding-left: 15px;
  margin-top: -8px;
  background-color: #093432;
  color: white;
}

#cookies {
  /*width: 100vw;*/
  height: 100vh;
  overflow: scroll;
  background-color: #FFFFFF;
}

#cookiesContainer {
  position: relative;
  top: 45px;
  /*width: calc(100% - 30px);*/
  text-align: left;
  margin-top: 45px;
  margin-left: 15px;
  margin-right: 20px;
  padding-bottom: 45px;
  color: black;
  background-color: #FFFFFF;
  /*overflow: visible;*/
  font-size: 0.8em;
}

</style>