import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from "../components/HomePage"
import Privacy from "../components/Privacy"
import Cookies from "../components/Cookies";

Vue.use(VueRouter)

export default new VueRouter({
  routes: [
    {
      path: '/',
      name: 'HomePage',
      component: HomePage
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: Privacy
    },
    {
      path: '/cookies',
      name: 'Cookies',
      component: Cookies
    }
  ]
})